import React from 'react';

import Button from '@ui/Button';

import Owlbert from './laptop-owlbert.png';

const PreviewBanner = () => (
  <div className="rm-PreviewBanner">
    <div className="content-wrapper">
      <img alt="Owlbert smiling with a laptop" className="owlbert-banner" src={Owlbert} />
      <p className="signup-text">
        <strong>Like what you see?</strong> ReadMe makes it easy to create beautiful documentation for your API, by
        importing an OpenAPI/Swagger file… but that’s not all. You can build a community, write guides, and much more!
      </p>
      <Button bem={{ white: true }} className="signup-button" href="https://dash.readme.io/signup" size="sm">
        Sign Up for ReadMe
      </Button>
    </div>
  </div>
);

export default PreviewBanner;
